$ASSETS_URL:"https://asset.programmers.co.kr/school-assets";
@if variable-exists(ASSETS_URL) {
  //NotoSansKR
  @font-face {
    font-display: swap;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 100;
    src: url($ASSETS_URL + '/fonts/NotoSansKR/NotoSansKR-Thin.woff2')
        format('woff2'),
      url($ASSETS_URL + '/fonts/NotoSansKR/NotoSansKR-Thin.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 300;
    src: url($ASSETS_URL + '/fonts/NotoSansKR/NotoSansKR-Light.woff2')
        format('woff2'),
      url($ASSETS_URL + '/fonts/NotoSansKR/NotoSansKR-Light.woff')
        format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: url($ASSETS_URL + '/fonts/NotoSansKR/NotoSansKR-Regular.woff2')
        format('woff2'),
      url($ASSETS_URL + '/fonts/NotoSansKR/NotoSansKR-Regular.woff')
        format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: url($ASSETS_URL + '/fonts/NotoSansKR/NotoSansKR-Medium.woff2')
        format('woff2'),
      url($ASSETS_URL + '/fonts/NotoSansKR/NotoSansKR-Medium.woff')
        format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: url($ASSETS_URL + '/fonts/NotoSansKR/NotoSansKR-Bold.woff2')
        format('woff2'),
      url($ASSETS_URL + '/fonts/NotoSansKR/NotoSansKR-Bold.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 900;
    src: url($ASSETS_URL + '/fonts/NotoSansKR/NotoSansKR-Black.woff2')
        format('woff2'),
      url($ASSETS_URL + '/fonts/NotoSansKR/NotoSansKR-Black.woff')
        format('woff');
  }

  // Inter
  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-Thin.woff2') format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-Thin.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-ThinItalic.woff2')
        format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-ThinItalic.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-ExtraLight.woff2')
        format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-ExtraLight.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 200;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-ExtraLightItalic.woff2')
        format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-ExtraLightItalic.woff')
        format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-Light.woff2') format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-Light.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 300;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-LightItalic.woff2')
        format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-LightItalic.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-Regular.woff2') format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-Regular.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-Italic.woff2') format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-Italic.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-Medium.woff2') format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-Medium.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 500;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-MediumItalic.woff2')
        format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-MediumItalic.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-SemiBold.woff2') format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-SemiBold.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 600;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-SemiBoldItalic.woff2')
        format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-SemiBoldItalic.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-Bold.woff2') format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-Bold.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 700;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-BoldItalic.woff2')
        format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-BoldItalic.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-Black.woff2') format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-Black.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 900;
    src: url($ASSETS_URL + '/fonts/Inter/Inter-BlackItalic.woff2')
        format('woff2'),
      url($ASSETS_URL + '/fonts/Inter/Inter-BlackItalic.woff') format('woff');
  }
}
